import { LineItem } from '@shopware-pwa/types';
import { addPromotionCode } from '@shopware-pwa/api-client';
import { useI18n } from 'vue-i18n';

const voucherLoading = ref(false);

export default () => {
  const { refreshCart, removeItem } = useCart();
  const { pushError, pushSuccess } = useNotifications();
  const { apiInstance } = useShopwareContext();
  const { t } = useI18n();

  return {
    voucherLoading: computed(() => voucherLoading.value),
    applyVoucher: async (promoCode: string) => {
      voucherLoading.value = true;
      try {
        const response = await addPromotionCode(promoCode, apiInstance);

        const isAdded = Object.keys(response.errors).filter((k) =>
          k.startsWith('promotion-discount-added')
        );

        if (Object.keys(response.errors).length && !isAdded.length) {
          pushError(t('components.voucher.error'));
        } else {
          pushSuccess(t('components.voucher.success'));
          await refreshCart();
        }
      } catch (error) {
        pushError(t('components.voucher.error'));
        console.error(error);
      }
      voucherLoading.value = false;
    },
    removeVoucher: async (lineItem: LineItem) => {
      voucherLoading.value = true;
      try {
        await removeItem(lineItem);
        pushSuccess(t('components.voucher.removeSuccess'));
      } catch (error) {
        pushError(t('components.voucher.error'));
      }
      voucherLoading.value = false;
    },
  };
};
